import React,{useEffect,useState} from 'react'
import Privacybanner from '../../assets/privacy/privacy.png';
import Termsbanner from '../../assets/privacy/terms.svg';

import CommonFooter from '../common_footer/common_footer';

// api 
import { ApiHelper } from '../../utilFunctions/utilfunction';

const Privacy = () => {
    
    const[privacy,setPrivacy] = useState("");
    const[terms,setTerms] = useState("");

    useEffect (async () =>{
        
        let url ="/privacy_policy"
        let privacydata = await ApiHelper(url);
        // console.log(privacydata);
        setPrivacy(privacydata);
        
        let url2 ="/terms_condition"
        let termsdata = await ApiHelper(url2);
        // console.log(termsdata);
        setTerms(termsdata);
    }, [])

    // setTimeout(() =>{
    //     document.getElementById("dangerous").innerHTML = privacy.data;
    // },1000)     

    // setTimeout(() =>{
    //     document.getElementById("dangerous2").innerHTML = terms.data;
    // },1000)     
    return (

    <section>
    <div className='container p-navbar ps-lg-0 pe-lg-0'>
       
        {
            privacy && privacy.data.length?(
                <section>
                    <div className='d-flex justify-content-center pt-3 pb-5'>
                        <img className='mw-100' src={Privacybanner} alt="banner"/>
                    </div>
                    <p className='article_imagess fs-16 fw-400' dangerouslySetInnerHTML={{__html: `${privacy.data}`}}></p>
                </section>
            ):""
        }
        {
            terms && terms.data.length ?(
                <section>
                    <div className='d-flex justify-content-center pt-5 pb-5'>
                        <img className='mw-100' src={Termsbanner} alt="banner"/>
                    </div>
                    <p className='article_imagess fs-16 fw-400' dangerouslySetInnerHTML={{__html: `${terms.data}`}}></p>
                </section>
            ):""
        }
    </div>
    {/* <CommonFooter/> */}
    </section>
  )
}

export default Privacy;