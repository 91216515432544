export const ApiHelper = async (url, data = {}, method = "GET") => {

  try {
    let objtopass = {
      method: method,
      // headers: {
      //   'appaccesstoken': 'A7UVIN#3943=T@b^Nbdhb7s3Sf_v@Tech_VN',
      //   'Content-Type': 'application/json',
      // },
      headers: {
        'appaccesstoken': 'A7UVIN#3943=T@b^Nbdhb7s3Sf_v@Tech_VN',
        'Content-Type': 'application/json',
      },
    };

    if (method === "POST") {
      objtopass.body = JSON.stringify(data)
    }

    // let baseURL = "http://192.168.0.136/admin-bjsonline/api";

    let baseURL = "https://admin.bjsonline.com/api";

    let response = await fetch(`${baseURL}${url}`, objtopass);
    // let response = await fetch(`${baseURL}${url}`, objtopass);

    return response.json();
  } catch (error) {
    console.log(error, 'error');
  }
};