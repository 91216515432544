import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PrismaZoom from "react-prismazoom";

import "./bootstrap_carousel.css";

const Bootstrap_carousel = (props) => {
  const [vintagewatches, setVintagewatches] = useState(props.array);
  return (
    <section className="w-100 d-flex justify-content-center">
      <Carousel fade>
        {vintagewatches
          ? vintagewatches.map((value, index) => (
            <Carousel.Item className="carousel_image" key={index}>
              {/* <TransformWrapper key={index}>
                  <TransformComponent key={index}> */}
              <PrismaZoom key={index}>
                <img
                  className="d-block w-100"
                  src={value.image_full_path}
                  alt="image"
                />
              </PrismaZoom>
              {/* </TransformComponent>
                </TransformWrapper> */}
            </Carousel.Item>
          ))
          : ""}
      </Carousel>
    </section>
  );
};

export default Bootstrap_carousel;
