import React, { useEffect, useState } from 'react';
import './navbar.css'
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/navbar/logo.svg';


const Navbar = () => {

  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    let leftside = document.getElementById("dropdown_left");
    let rightside = document.getElementById("dropdown_right");

    window.addEventListener('scroll', (event) => {

      leftside.classList.remove('show');
      rightside.classList.remove('show');
      setShowResults(false);
    })


  }, [window.location.pathname])


  let navigate = useNavigate();

  const [showResults, setShowResults] = useState(false);


  const toggledropdown = () => {

    if (showResults === false) {

      setShowResults(true);
      let a = document.getElementById("dropdown_image")
      a.classList.add("active")
    }
    if (showResults === true) {
      setShowResults(false);
      let b = document.getElementById("dropdown_image")
      b.classList.remove("active")
    }

  }
  const rolex = () => {

    navigate("/rolex")
    let b = document.getElementById("unique")
    b.classList.add("active")

  }
  const vintage = () => {

    navigate("/vintage-rolex-watches")
    let c = document.getElementById("unique")
    c.classList.add("active")

  }

  const vintageWatchPart = () => {

    navigate("/watch-parts")
    let c = document.getElementById("unique")
    c.classList.add("active")

  }

  const archive = () => {

    navigate("/archive")
    let d = document.getElementById("unique")
    d.classList.add("active")
  }
  const navbarlink = () => {

    let e = document.getElementById("unique")
    e.classList.remove("active")

    let f = document.getElementById("dropdown_image")
    f.classList.remove("active")

    setShowResults(false)
    console.log();
  }

  setTimeout(() => {
    if (window.location.pathname === '/vintage-rolex-watches' || window.location.pathname === '/rolex' || window.location.pathname === "/vintage" || window.location.pathname === "/vintagedetail" || window.location.pathname === "/vintagepurchase" || window.location.pathname === "/rolexdetail" || window.location.pathname === "/rolexpurchase" || window.location.pathname === "/watches" || window.location.pathname === "/purchase-watch" || window.location.pathname === "/archive" || window.location.pathname === "/archivedetail") {

      let l = document.getElementById("unique");
      l.classList.add("active")
    }
  }, 1000)

  setTimeout(() => {

    if (window.location.pathname === '/archive-articles' || window.location.pathname === "/articles" || window.location.pathname === "/contact-us" || window.location.pathname === "/sell-your-watch" || window.location.pathname === "/privacy-policy") {

      let g = document.getElementById("unique");
      g.classList.remove("active")

      if (g.className === "nav-link position-relative pe-3 cursor-pointer active") {
        g.classList.remove("active")
      }
    }
  }, 1000);

  setTimeout(() => {
    if (window.location.pathname === '/rolexdetail') {

      let h = document.getElementById("unique");

      h.classList.add("active")

    }
  }, 1000);

  setTimeout(() => {

    let o = document.getElementById("unique")

    if (window.location.pathname === '/rolex' || window.location.pathname === '/rolexdetail' || window.location.pathname === '/rolexpurchase') {
      let z = document.getElementById("rolex_submenu_active")
      z && z.classList.add('smallactive');
      o.classList.add("active2")
    }

    if (window.location.pathname === '/vintage' || window.location.pathname === '/vintagedetail' || window.location.pathname === '/vintagepurchase' || window.location.pathname === '/vintage-rolex-watches') {
      let y = document.getElementById("vintage_submenu_active")
      y && y.classList.add('smallactive');
    }

    if (window.location.pathname === '/archive' || window.location.pathname === '/archivedetail') {
      console.log("archive")
      let x = document.getElementById("archive_submenu_active")
      x && x.classList.add('smallactive');
    }

    if (window.location.pathname === '/articles' || window.location.pathname === '/archive-articles') {
      let w = document.getElementById("articlee")
      w && w.classList.add('active');
    }
    else {
      let w = document.getElementById("articlee")
      w && w.classList.remove('active');
    }

    let blo = document.getElementById("blog");
    let pathname = window.location.pathname;
    let blogss = pathname.includes("/blog") || pathname.includes("/blogs");
    blogss == true ? blo.classList.add("active") : blo.classList.remove("active");

    let arti = document.getElementById("articlee");
    let articless = pathname.includes("/archive-articles") || pathname.includes("/articles");
    articless == true ? arti.classList.add("active") : arti.classList.remove("active");

    let watch_part = pathname.includes("/purchase-watch-part") || pathname.includes("/watch-parts");

    let collection = document.getElementById("unique");
    let part = document.getElementById("watch-parts");

    let collectionns =
      pathname.includes("/purchase-watch") ||
      pathname.includes("/watches") ||
      pathname.includes("/vintage-rolex-watches");

    // Check if it's a purchase-watch-part route
    if (watch_part) {
      part.classList.add("active");
    } else {
      part.classList.remove("active");
      // Apply the class based on other conditions
      collectionns ? collection.classList.add("active") : collection.classList.remove("active");
    }

  }, 500);
  return (

    <section>

      <header className="header container-fluid">
        <nav className="navbar container container23 navbar-expand-lg navbar-light">

          <div className="container-fluid cont_fluid">

            {/* navbar  leftside */}
            <div id="dropdown_left" className="collapse navbar-collapse order-lg-1 order-3 navigation-menu">
              <ul className="navbar-nav">
                <li className="homeee text-uppercase nav-item fs-14 fw-500">
                  <NavLink exact to="/introduction" className="nav-link" onClick={() => navbarlink()}>
                    Home
                  </NavLink>
                </li>
                <li className="collectionnn text-uppercase nav-item fs-14 fw-500">
                  <div className="nav-link position-relative pe-3 cursor-pointer" id="unique" onClick={() => toggledropdown()}>
                    Our Collection
                    <svg className="svg-inline--fa fa-chevron-down fa-w-14 fa-3x position-absolute" id="dropdown_image" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z">
                      </path>
                    </svg>
                    {showResults && (
                      <div className='dropdown_menu d-flex flex-column'>
                        <a onClick={() => vintage()} id="vintage_submenu_active" className='text-decoration-none color-primary m-0 pt-2 pt-lg-0 cursor-pointer'>Discover Our Vintage Rolex Collection</a>
                      </div>
                    )}
                  </div>
                </li>
                <li className="watch-parts text-uppercase nav-item fs-14 fw-500">
                  <NavLink exact to="/watch-parts" id="watch-parts" className="nav-link" onClick={() => navbarlink()}>
                    Watch Parts
                  </NavLink>
                </li>
              </ul>
            </div>

            {/* navbar center icon */}
            <NavLink to="/" className="navbar-brand order-1" onClick={() => navbarlink()}>
              <img className='brand_logo' src={Logo} id="white_logo" alt="White_logo" />
            </NavLink>
            <button className="navbar-toggler order-2 custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navigation-menu">
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* navbar rightside */}
            <div id="dropdown_right" className="right collapse navbar-collapse order-lg-3 order-4 navigation-menu">
              <ul className="navbar-nav">
                <li className="articlee text-uppercase nav-item fs-14 fw-500">
                  <NavLink exact to="/archive-articles" id="articlee" className="nav-link" onClick={() => navbarlink()}>
                    Articles
                  </NavLink>
                </li>
                <li className="blogg text-uppercase nav-item fs-14 fw-500">
                  <NavLink exact="true" id="blog" to="/blog" className="nav-link" onClick={() => navbarlink()}>
                    Blogs
                  </NavLink>
                </li>
                <li className="watchh text-uppercase nav-item fs-14 fw-500">
                  <NavLink exact="true" id="sellwatch" to="/sell-your-watch" className="nav-link" onClick={() => navbarlink()}>
                    Sell your Watch
                  </NavLink>
                </li>

                <li className="contactt text-uppercase nav-item fs-14 fw-500">
                  <NavLink exact="true" to="/contact-us" className="nav-link" onClick={() => navbarlink()}>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>

          </div>

        </nav>
      </header >

    </section >
  )
}

export default Navbar;
