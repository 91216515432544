import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';

import { Route, Routes } from 'react-router-dom';

import Scroll from './component/scroll_to_top/scroll';

import Homepage from './component/homepage/homepage';
import Homepage2 from './component/homepage/homepage2';
import Article from './component/article/article';
import SellWatch from './component/sellwatch/sellwatch';
import ContactUs from './component/contactus/contact';

import ArticleList from './component/article/articleList';

import Vintage from './component/collection/vintage';
import Rolex from './component/collection/rolex';
import Archive from './component/collection/archive';

import ProductDetail from './component/product_detail/pd';
import PdContact from './component/product_detail/pd_contact';

import Archivepd from './component/collection/archive_pd';
import Vintagepd from './component/collection/vintage_pd';
import Rolexpd from './component/collection/rolex_pd';

import Rolexpurchase from './component/collection/rolex_purchase.js';
import Vintagepurchase from './component/collection/vintage_purchase.js';

import Privacy from './component/privacy/privacy';

import Error from './component/error/error';

import Rolex_vintage from './component/collection/rolex_vintage';
import PageLayout from './component/pagelayout/layout'
import Blog from './component/blog/blog';
import BlogList from './component/blog/blogList';
import Watch_Part from './component/collection/watch_part.js';
import Watch_parts_pd from './component/collection/watch_part_pd.js';
import Watch_part_purchase from './component/collection/watch_part_purchase.js';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<PageLayout />}>


          <Route exact path="/introduction" element={<Homepage2 />}></Route>

          <Route exact path="/rolex" element={<Rolex />}></Route>
          <Route exact path="/rolexdetail" element={<Rolexpd />}></Route>
          <Route exact path="/rolexpurchase" element={<Rolexpurchase />}></Route>

          <Route exact path="/vintage" element={<Vintage />}></Route>
          <Route exact path="/watches/:id/:title" element={<Vintagepd />}></Route>
          <Route exact path="/purchase-watch/:id/:title" element={<Vintagepurchase />}></Route>

          <Route exact path="/archive" element={<Archive />}></Route>
          <Route exact path="/archivedetail" element={<Archivepd />}></Route>

          <Route exact path="/archive-articles" element={<Article />}></Route>
          <Route exact path="/articles/:id/:title" element={<ArticleList />}></Route>

          <Route exact path="/blog" element={<Blog />}></Route>
          <Route exact path="/blogs/:id/:title" element={<BlogList />}></Route>

          <Route exact path="/privacy-policy" element={<Privacy />}></Route>

          <Route exact path="/sell-your-watch" element={<SellWatch />}></Route>
          <Route exact path="/contact-us" element={<ContactUs />}></Route>

          <Route exact path="/productdetail" element={<ProductDetail />}></Route>
          <Route exact path="/productdetailcontact" element={<PdContact />}></Route>

          <Route exact path="/vintage-rolex-watches" element={<Rolex_vintage />}></Route>

          <Route exact path="/watch-parts" element={<Watch_Part />}></Route>
          <Route exact path="/watch-parts/:id/:title" element={<Watch_parts_pd />}></Route>
          <Route exact path="/purchase-watch-part/:id/:title" element={<Watch_part_purchase />}></Route>

        </Route>

        <Route exact path="" element={<Homepage />}></Route>
        <Route path="*" element={<Homepage />}></Route>
      </Routes>

      <Scroll />

    </div>
  );
}

export default App;
