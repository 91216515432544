import React from 'react';

import Carousel from './carousel/carousel';

import CommonFooter2 from '../common_footer/common_footer2'

const Pd = () => {
  
    return (
        <section className='pd p-navbar'>

            <div className='container'>
                <div className='d-flex flex-wrap'>
                    <div className='col-12 col-lg-6'>
                        <p className='pt-5 color-secondary text-uppercase fs-14'>Experience our Rolex Collection</p>
                        <h2 className='fs-40 fw-500 m-0'>Rolex Oyster</h2>
                        <h2 className='fs-40 fw-500 '>Perpetual DeepSea</h2>
                        <h5 className='fs-18 fw-500 text-uppercase'>serial no: 1419210 Circa 1966</h5>
                        <h5 className='fs-18 fw-500 text-uppercase'>Ref: 6239</h5>
                        <p className='col-lg-11 color-primary fs-16 fw-400'>Ref 6239 is the first Rolex chronograph with the tachymeter scale engraved on the bezel: the trademark of what become one of the most successful, desired and recognizable line of timepieces ever created. </p>
                        <p className='col-lg-11 color-primary fs-16 fw-400'>The present example is made even more desirable as of the incredibly sought-after retailers signature on the dial, originally retailed by Tiffany & Co. in New York as well as ROW stamped on the bridge of the balance wheel, confirming it was produced especially for the American market. </p>
                        <p className='col-lg-11 color-primary fs-16 fw-400'>Notably, it features a larger and longer DAYTONA text below ROLEX COSMOGRAPH and with the TIFFANY & CO. signature below that, it makes for the pleasing effect of the text being in the shape of a pyramid </p>
                        <button className='btn fs-16 fw-500 hover2 rounded-3'>Contact Us</button>
                    </div>
                    <div className='col-12 col-lg-6 pt-5'>

                        <Carousel/>

                    </div>
                </div>
            </div>

            <CommonFooter2/>

        </section>
    );
};

export default Pd;
