import React, {useState,useEffect} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import './scroll.css';

const ScrollButton = () =>{

	const [visible, setVisible] = useState(false)

	const toggleVisible = () => {

		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 100){
		setVisible(true)
		}
		else if (scrolled <= 100){
		setVisible(false)
		}
	};

	const scrollToTop = () =>{
		window.scrollTo({
		top: 0,
		behavior: 'smooth'
		});
	};

	window.addEventListener('scroll', toggleVisible);


	const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);

	useEffect(() => {
	    const handleScroll = () => {
	        setIsScrollValueMoreThanHeaderHeight(window.scrollY > 90);
	    }
	    window.addEventListener('scroll', handleScroll)
	    return () => window.removeEventListener('scroll', handleScroll);
	},[])

	return (

		<span className={isScrollValueMoreThanHeaderHeight ? "hello_hello1" : "hello_hello2"} onClick={scrollToTop}>
		    <FaArrowCircleUp  className="arrow" style={{display: visible ? 'inline' : 'none'}} />
		</span>
	);
}

export default ScrollButton;
