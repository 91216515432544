import React, { useState, useEffect } from "react";

import Header from "../../../assets/homepage/page2_heading.svg";

import UpperWatch1 from "../../../assets/homepage/page2_upper1.svg";
import UpperWatch2 from "../../../assets/homepage/page2_upper3.png";
import UpperWatch3 from "../../../assets/homepage/page2_upper2.svg";
import UpperWatch4 from "../../../assets/homepage/page2_upper4.svg";

// api
import { ApiHelper } from "../../../utilFunctions/utilfunction";
import { useNavigate } from "react-router";                                                                                                                                                                                                                                                                                                                           

const Page2 = () => {
  const [vintage, setVintage] = useState();
  const [rolex, setRolex] = useState([]);
  const [data, setdata] = useState();

  useEffect(async () => {
    let url = "/get_vintage_watch_list";
    let vintagedata = await ApiHelper(url);
    // console.log(vintagedata,"vintage data hai");
    setVintage(vintagedata);

    // let url2 ="/get_watch_list"
    // let rolexdata = await ApiHelper(url2);
    // console.log(rolexdata,"reolex data hai");
    // setRolex(rolexdata);

    // let mainpage = [];

    // let alldata = mainpage.concat(vintagedata.data,rolexdata.data);
    // setdata(alldata);
    // console.log(alldata,"all data");

    // let datavintage = vintagedata.data;
    // let splicevintage = datavintage.length ? datavintage.splice(0,4) : [] ;
    // setVintage(splicevintage);

    // let datarolex = rolexdata.data;
    // let splicerolex =  datarolex.length ? datarolex.splice(0,4) : [] ;
    // setRolex(splicerolex);
    // console.log(splicerolex);
  }, []);

  // console.log(data,"all data aave che");
  const navigate = useNavigate();  

  return (
    <section className="homepage3 container ps-lg-0 pe-lg-0">
        
      {vintage && vintage.data.length ? (
        <div className="text-center">
          <img className=" mw-100" src={Header} alt="header" />
        </div>
      ) : (
        ""
      )}
      <section>
        <div className=" d-flex flex-column flex-sm-row flex-wrap pt-4">
          {vintage && vintage.data.length
            ? vintage.data.map((value, index) => (
                <div
                  className="col-12 col-sm-6 col-lg-3 mb-2 mb-lg-4"
                  key={index}
                >
                  <div
                    className="card cursor-pointer shadow_hover"
                    style={{ border: "none" }}
                    onClick={() =>
                      navigate(
                        `/watches/p${value.id}/${value.title
                          .replaceAll("/", "-")
                          .replaceAll(" ", "-")
                          .toLowerCase()}`,
                        { state: value }
                      )
                    }
                  >
                    <div className="card-body text-center">                      
                      <div className="watches">
                        <img
                          className="mw-100"
                          src={
                            value.image_full_path
                              ? value.image_full_path
                              : UpperWatch2
                          }
                          alt="upperwatch1"
                        />
                      </div>
                      <p className="px-2 fs-16 fw-400 for_height_list">
                        {value.title}
                      </p>
                      <button className="btn hover2 fs-14 fw-500 button_pd24">
                      More info
                      </button>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </section>
    </section>
  );
};

export default Page2;
