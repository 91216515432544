import React from 'react';
import CommonFooter from '../common_footer/common_footer'

import Carousel from './carousel/carousel';

import ReCAPTCHA from 'react-google-recaptcha';

import SubmitIcon from  '../../assets/contactus/submit.svg';

// validation
import { useForm } from 'react-hook-form';

// API
import { ApiHelper } from '../../utilFunctions/utilfunction';

const Pd_contact= () => {
    
    const {register,handleSubmit,formState:{errors},reset,trigger} = useForm();

    const Submit = (event) =>{
        reset();

        // For API
        const name = event.name
        const email = event.email
        const phone = event.phone
        const watch = event.watch
        const ref = event.ref
        const message = event.message
        
        const reqToSend = {

            name:name,
            email: email,
            phone: phone,
            watch : watch,
            ref : ref,
            message: message,
        }

        let url = "https://pedalapi.gymclock.app:5001/product-detail/get-product-attr/colour";
        ApiHelper(url, reqToSend, "POST")
        .then(response => response.json())
    }

    return (
        <section className='Pd_contact p-navbar'>
            <div className='container'> 
            <form className="form pt-4" onSubmit={handleSubmit(Submit)}>
                <h4 className='fw-bold text-uppercase pt-5'>Customer order information</h4>
                <div className='d-flex flex-column-reverse flex-lg-row'>
                    <div className='col-12 col-lg-5'>                        
                        {/*** Form ***/}
                        <div className='pt-4'>

                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("name", { required: "Name is required"})}  onKeyUp={()=>{ trigger("name") }}/>
                                {errors.name && (<p className='position-absolute text-danger'>{errors.name.message}</p>)}
                                <label htmlFor="inputText">Customer Name*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text"
                                {...register("email", { required: "Email is required", 
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid Email Address"
                                        }
                                    })
                                } 
                                onKeyUp={()=>{
                                    trigger("email")
                                }}/>
                                {errors.email && (<p className='position-absolute text-danger'>{errors.email.message}</p>)}
                                <label htmlFor="inputText">Email Address*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="number" 
                                {...register("phone", { required: "Phone Number is required", 
                                        pattern: {
                                            value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                            message: "Invalid Phone Number"
                                        }
                                    })
                                }
                                onKeyUp={()=>{ trigger("phone")}}/>
                                {errors.phone && (<p className='position-absolute text-danger'>{errors.phone.message}</p>)}
                                <label htmlFor="inputText">Phone Number*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("watch", { required: "Make of Watch is required"})} onKeyUp={()=>{ trigger("watch") }}/>
                                {errors.watch && (<p className='position-absolute text-danger'>{errors.watch.message}</p>)}
                                <label htmlFor="inputText">Make of Watch*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("ref", { required: "Ref is required"})} onKeyUp={()=>{ trigger("ref") }}/>
                                {errors.ref && (<p className='position-absolute text-danger'>{errors.ref.message}</p>)}
                                <label htmlFor="inputText">Ref*</label>
                            </div>
                            
                            <div className="textOnInput textOnInput2 mb-4">
                                <textarea className="form-control" rows="3" 
                                {...register("message", { required: "Message is required",
                                    minLength: {
                                        value: 10,
                                        message: 'Minimum 10 Character is Required' 
                                    },
                                    maxLength : {
                                        value: 120,
                                        message: 'Maximum 120 Character is Required'
                                    }
                                })} 
                                onKeyUp={()=>{ trigger("message") }}/>
                                {errors.message && (<p className='position-absolute text-danger'>{errors.message.message}</p>)}
                                <label htmlFor="exampleFormControlTextarea1" className="form-label m-0">Additional Message*</label>
                            </div>
                            
                            {/*** Recaptcha ***/}
                            <ReCAPTCHA sitekey='6LcGAtEgAAAAAHyP6eLkslZXPBdAVZA0NHYMgBlo'/>

                        </div>
    
                    </div>
                    <div className='col-12 col-lg-7'>
                            
                        <Carousel/>

                    </div>
                    
                </div>
                <div className='d-flex flex-wrap flex-md-nowrap mt-4 mt-md-5 mt-lg-4 align-items-center'>
                    <button type="submit" className='btn hover2  fw-500 submit_button'>Submit</button>
                    <div className='d-flex align-items-center pt-4 pt-md-0'><img className='ps-md-4 pe-2' src={SubmitIcon} alt=""/><span className='fs-6'>Thanks for showing interest in making a purchase. We have received your request and will contact you shortly</span></div>
                </div>
            </form>
            </div>

            <CommonFooter/>

        </section>
    );
};

export default Pd_contact;
