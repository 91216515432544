import React, { useState, useEffect } from 'react';
import './page3.css';

import BottomWatch1 from '../../../assets/homepage/page2_watch1.svg';
import BottomWatch2 from '../../../assets/homepage/page2_watch2.svg';

import { useNavigate } from 'react-router';

// api 
import { ApiHelper } from '../../../utilFunctions/utilfunction';

const Page3 = () => {

    const [banner, setBanner] = useState();

    const [banner2, setBanner2] = useState();

    const [banner3, setBanner3] = useState();

    const [banner4, setBanner4] = useState();

    useEffect(async () => {

        let url = "/banner"
        let bannerdata = await ApiHelper(url);
        // console.log(bannerdata);
        setBanner(bannerdata);

        let url2 = "/page_banner_image?page_banner_key=vintage_banner_image"
        let bannerdata2 = await ApiHelper(url2);
        // console.log(bannerdata2.data["image_full_ path"]);
        setBanner2(bannerdata2.data["image_full_ path"]);

        let url3 = "/page_banner_image?page_banner_key=article_banner_image"
        let bannerdata3 = await ApiHelper(url3);
        // console.log(bannerdata2.data["image_full_ path"]);
        setBanner3(bannerdata3.data["image_full_ path"]);

        let url4 = "/page_banner_image?page_banner_key=blog_banner_image"
        let bannerdata4 = await ApiHelper(url4);
        // console.log(bannerdata2.data["image_full_ path"]);
        setBanner4(bannerdata4.data["image_full_ path"]);

    }, [])

    var divStyle2 = {
        backgroundImage: 'url(' + banner2 + ')'
    }
    var divStyle3 = {
        backgroundImage: 'url(' + banner3 + ')'
    }
    var divStyle4 = {
        backgroundImage: 'url(' + banner4 + ')'
    }
    const navigate = useNavigate();

    const handleclickrolexvintage = (e) => {

        navigate("/vintage-rolex-watches")

        if (e.ctrlKey) {
            window.open('/vintage-rolex-watches', '_blank')
        }
    }
    const handleclickarticle = (e) => {

        navigate("/archive-articles")

        if (e.ctrlKey) {
            window.open('/archive-articles', '_blank')
        }
    }
    const handleclickblog = (e) => {

        navigate("/blog")

        if (e.ctrlKey) {
            window.open('/blog', '_blank')
        }
    }
    return (
        <section className='homepage3 container pt-4'>

            <div className='row d-flex justify-content-between'>
                {
                    banner2 !== undefined &&
                    <div className='col-12 col-lg-4 mb-4 position-relative'>
                        <div className='card imagee1 rounded-0 cursor-pointer' style={divStyle2} onClick={(e) => handleclickrolexvintage(e)}>
                            <div className='position-absolute d-flex flex-column lowerimage_content'>
                                <p className='fw-bold text-light text-center fs-24 fw-700 m-0 pb-2 m-sm-2 over-992-center page3_z-index2'>Discover Our Vintage Rolex Collection</p>
                                <div className='text-center page3_z-index2'>
                                    <button className='btn color18 hover p-1 ps-3 pe-3 ps-sm-4 fs-16 pe-sm-4 fw-700'>Explore</button>
                                </div>
                            </div>
                            <div className="overlay-landingpage"></div>
                        </div>
                    </div>
                }
                {
                    banner3 !== undefined &&
                    <div className='col-12 col-lg-4 mb-4 position-relative'>
                        <div className='card imagee1 rounded-0 cursor-pointer' style={divStyle3} onClick={(e) => handleclickarticle(e)}>
                            <div className='position-absolute d-flex flex-column lowerimage_content'>
                                <p className='fw-bold text-light text-center fs-24 fw-700 m-0 pb-2 m-sm-2 over-992-center page3_z-index2'>Discover Our Articles</p>
                                <div className='text-center page3_z-index2'>
                                    <button className='btn color18 hover p-1 ps-3 pe-3 ps-sm-4 fs-16 pe-sm-4 fw-700'>Explore</button>
                                </div>
                            </div>
                            <div className="overlay-landingpage"></div>
                        </div>
                    </div>
                }
                {
                    banner4 !== undefined &&
                    <div className='col-12 col-lg-4 mb-4 position-relative'>
                        <div className='card imagee1 rounded-0 cursor-pointer' style={divStyle4} onClick={(e) => handleclickblog(e)}>
                            <div className='position-absolute d-flex flex-column lowerimage_content'>
                                <p className='fw-bold text-light text-center fs-24 fw-700 m-0 pb-2 m-sm-2 over-992-center page3_z-index2'>Discover Our Blogs</p>
                                <div className='text-center page3_z-index2'>
                                    <button className='btn color18 hover p-1 ps-3 pe-3 ps-sm-4 fs-16 pe-sm-4 fw-700 page3_z-index2'>Explore</button>
                                </div>
                            </div>
                            <div className="overlay-landingpage"></div>
                        </div>
                    </div>
                }


            </div>

        </section>
    )
};

export default Page3;
