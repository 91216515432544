import React from 'react'
import { Outlet } from 'react-router-dom';
import Navbar from '../../component/navbar/navbar';
import Footer from '../../component/footer/footer';

const Layout = () => {

    return (
        <section>
            <Navbar />
            <Outlet /> 
            <Footer />
        </section>
    )
}

export default Layout