import React,{useRef, useState} from 'react';
import './collection.css';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';

import UpperWatch2 from '../../assets/homepage/page2_upper3.png';

import CommonFooter from '../common_footer/common_footer';

import { useLocation } from "react-router-dom";

import BootstrapCarousel from '../collection/bootstrap_carousel';

const Archive_pd = () => {
    
    const dangerous = useRef(null);

    let location= useLocation(); 
    // console.log(location.state);

    const[watchdetail,setWatchdetail] = useState(location.state);
    // console.log(watchdetail);

    
    // const[dataa, setDataa] = useState(watchdetail.watch_type == "rolex" ? watchdetail.watch_detail.description : watchdetail.vinatge_detail.description  )
    // console.log(dataa);
    
    // setTimeout(() =>{
            
    //     document.getElementById("dangerous").innerHTML = location.state.description;

    // },1000) 

    const[display,setDisplay] = useState(false);
    const[allarray,setAllarray] = useState()

    const handleclick = (value) =>{
        
        // console.log(value)
        // console.log(watchdetail.vintage_detail.)
        let allwatches = watchdetail.vintage_detail.watch_images.filter((val)=>{
            return val.id  != value.id;
        })

        let valuearray = [value];
        let concatarray = valuearray.concat(allwatches);
        // console.log(concatarray);
        setAllarray(concatarray)
        setDisplay(true);
    }

    return (
        <section>
        {
            display ?
                <div className='position-fixed parent_class bg-gradient'>

                    <BootstrapCarousel array={allarray} />
                    <svg onClick={() => {setDisplay(false)}} className='position-fixed slider_close_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"/></svg>
                </div>
            : ""
        }

        <div className='container ps-lg-0 pe-lg-0 p-navbar'>
            <div className='d-flex flex-wrap'>
                <div className='col-12 col-lg-6'>
                    <p className='pt-5 color-secondary text-uppercase fs-14'>Experience our Rolex Vintage Collection</p>
                    <h2 className='fs-40 fw-500 m-0 pb-2 text-capitalize'>
                        { watchdetail.watch_type == "rolex" ? watchdetail.watch_detail.title : watchdetail.vintage_detail.title }
                    </h2>
                    <h5 className='fs-18 fw-500 text-uppercase'>serial no : { watchdetail.watch_type == "rolex" ? watchdetail.watch_detail.serial_no : watchdetail.vintage_detail.serial_no  }</h5>
                    <h5 className='fs-18 fw-500 text-uppercase'>Ref : { watchdetail.watch_type == "rolex" ? watchdetail.watch_detail.ref : watchdetail.vintage_detail.ref  }</h5>

                    <p className='col-lg-11 color-primary fs-16 fw-400'>{ watchdetail.watch_type == "rolex" ? watchdetail.watch_detail.description : watchdetail.vintage_detail.description }</p>
                    
                    <button className='btn fs-16 fw-500 bg-grey soldout rounded-3 ps-3 pe-3'>Sold Out</button>
                </div>
                <div className='col-12 col-lg-6 pt-5'>

                    {/****** Carousel **********/}

                    <section className="homepage4_thumb_carousel">

                        <Carousel autoplay showIndicators={false} showArrows={false} showStatus={false}>
                        {
                            watchdetail.watch_type == "rolex" ?

                                watchdetail.watch_detail.watch_images.length > 0 ? 
                                    watchdetail.watch_detail.watch_images.map((value,index)=>(
                                    
                                        <div className="imagge m-1 m-md-2" key={index} onClick={()=>handleclick(value)}>
                                            <img src={value["image_full_path"]} alt=""/>
                                        </div>
                                    ))
                                :
                                <div className="imagge m-1 m-md-2">
                                    <img src={UpperWatch2} alt=""/>
                                </div>
                            : 
                                watchdetail.vintage_detail.watch_images.length > 0 ? 
                                    watchdetail.vintage_detail.watch_images.map((value,index)=>(
                                    
                                        <div className="imagge m-1 m-md-2" key={index} onClick={()=>handleclick(value)}>
                                            <img src={value["image_full_path"]} alt=""/>
                                        </div>
                                    ))
                                :
                                <div className="imagge m-1 m-md-2">
                                    <img src={UpperWatch2} alt=""/>
                                </div>


                        }
                        </Carousel>
                    
                    </section>
                
                </div>
            </div>
        </div>
            
            <CommonFooter/>

        </section>
    );
};

export default Archive_pd;
