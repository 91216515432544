import React, { useState, useEffect } from 'react';
import { ApiHelper } from '../../utilFunctions/utilfunction';
// import Heading from '../../assets/homepage/page5_heading.svg';
import Heading from '../../assets/Blogs-RolexBlogs.svg';
import Noblog from '../../assets/homepage/noblog.svg';
import './blog.css';
import { useNavigate } from 'react-router';

const Blog = () => {
 
    const navigate = useNavigate();

    // For direct use of api data
    const[blog,setBlog] = useState();

    const[banner,setBanner] = useState();

    useEffect (async() =>{
        
        let url ="/page_banner_image?page_banner_key=blog_banner_image"
        let bannerdata = await ApiHelper(url);
        // console.log(bannerdata)
        setBanner(bannerdata.data["image_full_ path"]);

        let url2 = "/get_blog_list";
        let blogdata = await ApiHelper(url2);
        // console.log(blogdata,"abcd");
        setBlog(blogdata);
    }, [])

    var divStyle = {
        backgroundImage: 'url(' + banner + ')'
    }

    return (
        <section className='Blog p-navbar'>

            <div className='d-flex justify-content-center align-items-center position-relative bannerimage text-center mb-5' style={divStyle}>
                <h2 className='page3_z-index2 fs-48 pt-5 pb-5 text-light fw-bold'>Blogs </h2>
                <div className="overlay-landingpage"></div>
            </div>
            <div className='container p-0'>

                    <div className='text-center '>
                        <img className='mw-100' src={Heading} alt='header'/>
                    </div>
            {
                blog && blog.data.length?(
                    <div className='d-flex flex-column flex-sm-row flex-wrap pt-4 pb-0 pb-lg-4'>
                    {
                        blog.data.map((value,index)=>(
                        
                            <div className='col-12 col-sm-6 col-lg-4 col-xl-3 mb-3' key={index}>
                                <div className='card cursor-pointer shadow_hover' style={{border:'none'}} onClick={()=>navigate(`/blogs/p${value.id}/${value.slug}`,{state : value} )}>
                                    <div className='card-body position-relative'>
                                        <div className='watches flex-column mb-0 wathesss position-relative'>
                                            <img className='w-100 mw-100' src={value.image_path} alt="upperwatch1"/>
                                            <div className={`image_content col-12 bottom-0 start-0 p-0`}>
                                                <p className='fs-16 fw-400 text-capitalize text-center color-18191F for_height_list mt-3 mb-2'>{value.title}</p>
                                                <div className='d-flex justify-content-center mt-2'>
                                                    <button className='btn hover2 fs-14 fw-500 button_pd24'> Read more</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                )
                : ""
            }
            {
                blog?
                    blog.status == false &&
                    <div className='pb-5 mt-5 d-flex flex-column align-items-center justify-content-center'>
                        <img style={{width:'200px'}} src={Noblog} alt="noblog"/>
                        <h4 className='pt-3' style={{color:'#484848'}}>Comming Soon</h4>
                    </div>
                :""
            }
            
            </div>
        </section>
    )
}

export default Blog;
