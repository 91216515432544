import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router';
import CommonFooter from '../common_footer/common_footer4';
import './blog.css';
import moment from 'moment';

import { ApiHelper } from '../../utilFunctions/utilfunction';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router';
import { getValue } from '@testing-library/user-event/dist/utils';

const BlogList = () => {

  // for seo friendly start
  let { id } = useParams();
  let newid = id.replace('p', '');
 
  const [blogfriendly,setBlogfriendly] = useState();
  // for seo friendly End

  const[banner,setBanner] = useState();

  // let location= useLocation(); 
  const navigate = useNavigate();

  useEffect (async () =>{
    
    let url ="/page_banner_image?page_banner_key=blog_detail_banner_image"
    let bannerdata = await ApiHelper(url);
    setBanner(bannerdata.data["image_full_ path"]);

    let url2 = `/get_blog_detail/${newid}`;
    let blogdata = await ApiHelper(url2);
    setBlogfriendly(blogdata.data);
       
  }, [newid])
  useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
  },[window.location.pathname])


  var divStyle = {
    backgroundImage: 'url(' + banner + ')'
  }
  return (
    <section className='Blog p-navbar'>
      <div className='position-relative bannerimage text-center mb-5' style={divStyle}>
        <div className="overlay-landingpage"></div>
      </div>
      {
        blogfriendly?
          <div className='container ps-lg-0 pe-lg-0'>
    
              <h2 className='col-12 text-wrapp fw-700 text-dark f-42 text-uppercase'>{blogfriendly.title}</h2>

              <div className='d-flex justify-content-between flex-wrap'>
                <p className='d-flex align-items-center mb-2 mb-lg-0 fs-18 c-4e4e4e'>{moment(blogfriendly.article_date).format('DD MMMM YYYY')}</p>
                {/* <div className='d-flex flex-wrap align-items-center'>
                  {
                    blogfriendly.blog_categories.length >= 1 ?
                    <p className='category_heading mb-2 mb-lg-0 pe-1' style={{fontsize:'16px'}}>Categories:  </p>
                    : ""
                  }
                  <div className='d-flex flex-wrap categoriess'>
                  {
                    blogfriendly.blog_categories.map((val,index)=>(
                      <p className='bg-black category_fields text-light ms-2 rounded-3 mb-2 mb-lg-0' key={index}>{val.name}</p>
                    ))
                  }
                  </div>
                </div> */}
              </div>
              
              <div className='d-flex justify-content-center pt-2 pt-lg-5 pb-4'>
                  <div className='main_article_list_images'>
                      <img className='w-100 mw-100' src={blogfriendly.image_path} alt="image1"/>
                  </div>
              </div>
            
              <div className='article_imagess fs-16 fw-400 c-4e4e4e' dangerouslySetInnerHTML={{__html: `${blogfriendly.description}`}} ></div>
              {/* <div className='d-flex justify-content-center'>
                  <p className='onhover cursor-pointer c-4e4e4e fs-16 fw-400 pt-4 pb-4' onClick={()=>navigate("/blog")}>For more blogs</p>
              </div> */}
          </div>
        :""}
      {/* <CommonFooter/> */}
    </section>
  )
}

export default BlogList