import React, { useState, useRef, useEffect } from "react";
import "./collection.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import UpperWatch2 from "../../assets/homepage/page2_upper3.png";
import UpperWatch4 from "../../assets/homepage/page2_upper4.svg";

import CommonFooter2 from "../common_footer/common_footer2";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

import BootstrapCarousel from "../collection/bootstrap_carousel";
// api
import { ApiHelper } from "../../utilFunctions/utilfunction";
import { useParams } from "react-router";
import _ from "lodash";

const Vintage_pd = () => {
  // seo friendly start
  let { id } = useParams();
  let newid = id.replace("p", "");
  const [watchlistid, setWatchlistid] = useState(newid);
  const [watchList, setWatchList] = useState([]);
  const [currentListIndex, setCurrentListIndex] = useState();
  const [watchdetailfriendly, setWatchdetailfriendly] = useState();

  useEffect(async () => {
    let url = "/get_vintage_watch_list";
    let AllVintageData = await ApiHelper(url);
    _.findIndex(AllVintageData.data, (val, index) => {
      if (val.id === parseInt(watchlistid)) {
        setCurrentListIndex(index);
        setWatchList(AllVintageData.data);
      }
    });
  }, []);

  useEffect(() => {
    getWatchDetail(watchlistid);
  }, []);

  const getWatchDetail = async (w_id) => {
    let url2 = `/get_vintage_watch_detail/${w_id}`;
    let watchdetail123 = await ApiHelper(url2);
    let staticObj = {
      id: Math.floor(Date.now() / 1000),
      watch_id: Math.floor(Date.now() / 1000),
      image_path: watchdetail123.data.primary_image,
      status: 1,
      image_full_path: watchdetail123.data.image_full_path,
    };
    let data1 = watchdetail123;
    data1.data.watch_images.push(staticObj);
    setWatchdetailfriendly(data1.data);
  };

  const nextWatchDetail = () => {
    setCurrentListIndex(currentListIndex + 1);
    getWatchDetail(watchList[currentListIndex + 1].id);
  };

  const previousWatchDetail = () => {
    setCurrentListIndex(currentListIndex - 1);
    getWatchDetail(watchList[currentListIndex - 1].id);
  };
  const dangerous = useRef(null);

  let location = useLocation();
  // const[watchdetail,setWatchdetail] = useState(watchdetailfriendly);

  // setTimeout(() =>{

  //     document.getElementById("dangerous").innerHTML = watchdetailfriendly.description;

  // },2000)

  useEffect(() => {
    setWatchdetailfriendly(location.state);
  }, [location.state]);

  const navigate = useNavigate();

  const [display, setDisplay] = useState(false);
  const [allarray, setAllarray] = useState();

  const handleclick = (value) => {
    let allwatches = watchdetailfriendly.watch_images.filter((val) => {
      return val.id != value.id;
    });

    let valuearray = [value];
    let concatarray = valuearray.concat(allwatches);
    setAllarray(concatarray);
    setDisplay(true);
  };

  // for window width start
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);
  // for window width end

  return (
    <section>
      {display ? (
        <div className="position-fixed parent_class bg-gradient">
          <BootstrapCarousel array={allarray} />
          <svg
            onClick={() => {
              setDisplay(false);
            }}
            className="position-fixed slider_close_icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z" />
          </svg>
        </div>
      ) : (
        ""
      )}

      <div className="container ps-lg-0 pe-lg-0 p-navbar">
        <div
          className="col-12 col-lg-12 "
          style={{
            marginTop: "3%",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <button
            className="btn btn-sm hover2 rounded-3"
            style={{ fontSize: "0.675rem !important" }}
            disabled={currentListIndex === 0}
            onClick={() => {
              previousWatchDetail();
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Previous Watch"
          >
            {"<<"} Previous
          </button>
          <button
            className="btn btn-sm hover2 rounded-3"
            disabled={currentListIndex === watchList.length - 1}
            style={{ fontSize: "0.675rem !important" }}
            onClick={() => {
              nextWatchDetail();
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Next Watch"
          >
            Next {">>"}
          </button>
        </div>
        <div className="d-flex flex-wrap">
          {watchdetailfriendly ? (
            <div className="col-12 col-lg-6">
              <p className="pt-5 color-secondary text-uppercase fs-14">
                Experience our Vintage Rolex Collection
              </p>
              <h2 className="fs-40 fw-500 m-0 pb-2 text-capitalize">
                {watchdetailfriendly.title}
              </h2>
              <h5 className="fs-18 fw-500 text-uppercase">
                serial no: {watchdetailfriendly.serial_no}
              </h5>
              <h5 className="fs-18 fw-500 text-uppercase">
                Ref: {watchdetailfriendly.ref}
              </h5>

              <div
                className="article_imagess fs-16 fw-400 pb-3"
                dangerouslySetInnerHTML={{
                  __html: `${watchdetailfriendly.description}`,
                }}
              ></div>

              <h5 className="fs-18 fw-500 text-uppercase ">
                price: {watchdetailfriendly.price}
              </h5>

              <button
                className="btn fs-16 fw-500 hover2 rounded-3 ps-3 pt-2 pb-2 pe-3"
                onClick={() =>
                  navigate(
                    `/purchase-watch/p${
                      watchdetailfriendly.id
                    }/${watchdetailfriendly.title
                      .replaceAll("/", "-")
                      .replaceAll(" ", "-")
                      .toLowerCase()}`,
                    { state: watchdetailfriendly }
                  )
                }
              >
                Contact Us
              </button>
            </div>
          ) : (
            <div className="col-12 col-lg-6">
              <p className="pt-5 color-secondary text-uppercase fs-14">
                Experience our Vintage Rolex Collection
              </p>
              <button
                className="btn fs-16 fw-500 hover2 rounded-3 ps-3 pt-2 pb-2 pe-3"
                onClick={() =>
                  navigate(
                    `/purchase-watch/p${
                      watchdetailfriendly.id
                    }/${watchdetailfriendly.title
                      .replaceAll("/", "-")
                      .replaceAll(" ", "-")
                      .toLowerCase()}`,
                    { state: watchdetailfriendly }
                  )
                }
              >
                Contact Us
              </button>
            </div>
          )}

          <div className="col-12 col-lg-6 pt-5">
            {/****** Carousel **********/}

            <section className="homepage4_thumb_carousel">
              {/* <Carousel showIndicators={false} showArrows={true} showStatus={false} selectedItem={0} thumbWidth={windowDimenion.winWidth>1500?'150px':'120px'} > */}
              <Carousel
                showArrows={true}
                showIndicators={false}
                className={
                  watchdetailfriendly &&
                  watchdetailfriendly.watch_images.length > 5
                    ? ""
                    : "slides-center"
                }
              >
                {/* <div className="imagge m-1 m-md-2" onClick={()=>handleclick(value)}>
                        <img src={value["image_full_path"]} alt=""/>
                    </div> */}
                {watchdetailfriendly &&
                watchdetailfriendly.watch_images.length > 0 ? (
                  watchdetailfriendly.watch_images.map((value, index) => (
                    <div
                      className="imagge m-1 m-md-2"
                      key={index}
                      onClick={() => handleclick(value)}
                    >
                      <img src={value["image_full_path"]} alt="" />
                    </div>
                  ))
                ) : (
                  <div className="imagge m-1 m-md-2">
                    <img src={UpperWatch2} alt="" />
                  </div>
                )}
              </Carousel>
            </section>
          </div>
        </div>
      </div>
      {/* hello there!!
        <CommonFooter2/>  */}
    </section>
  );
};

export default Vintage_pd;
