import React,{useState,useEffect,useRef} from 'react';
import './page4.css';

import Header from '../../../assets/homepage/page3_heading.svg';
import Introduction from '../../../assets/homepage/page3_introduction.svg';

// api 
import { ApiHelper } from '../../../utilFunctions/utilfunction';

const Page4 = () => {
    
    const dangerous = useRef(null);

    const[intro,setIntro] = useState();

    useEffect (async () =>{
        
        let url ="/introduction"
        let introdata = await ApiHelper(url);
        // console.log(introdata,"hello");
        setIntro(introdata);

        // setTimeout(() =>{
            
        //     document.getElementById("dangerous").innerHTML = introdata.data.description;

        // },1000)   

    }, [])

    return (
        <section className='homepage4 pt-3 pb-5 mb-4 mb-lg-5'>

            <div className='container pe-lg-0'>
                {
                    intro?
                        <div className='text-center pb-4 pb-lg-5'>
                            <img className='mw-100' src={Header} alt='header'/>
                        </div>
                    :null
                }
               

                    {
                        intro?
                            <section>
                                <div className='d-flex flex-column flex-lg-row'>
                                    <div className='col-12 col-lg-7'>
                                        
                                        <div  dangerouslySetInnerHTML={{__html: `${intro.data.description}`}} id="dangerous" className='introductionn fs-16 fw-500 col-12 ps-lg-4 col-lg-10'></div>
                                        
                                    </div>
                                    <div className='col-12 col-lg-5'>

                                            <img className='introduction_image w-100 mw-100' src={intro.data.image_path} alt="intro"/>
                        
                                    </div>
                                </div>
                            </section>
                        :null
                    }
            </div>
        </section>
    )
}

export default Page4;
