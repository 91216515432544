import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import './carousel.css';
import image1 from '../../../assets/productdetail/img1.svg';
import image2 from '../../../assets/productdetail/img2.svg';
import image3 from '../../../assets/productdetail/img3.svg';
import image4 from '../../../assets/productdetail/img4.svg';

const Carousel_homepag4 = () => {
    return (
        <section className="homepage4_thumb_carousel">
            <Carousel autoplay showIndicators={false} showArrows={false} showStatus={false}>
                
                <div className="imagge m-1 m-md-2">
                    <img src={image1} alt=""/>
                </div>

                <div className="imagge m-1 m-md-2">
                    <img src={image2} alt=""/>
                </div>
                
                <div className="imagge m-1 m-md-2">
                    <img src={image3} alt=""/>
                </div>
            
                <div className="imagge m-1 m-md-2">
                    <img src={image4} alt=""/>
                </div>
            
            </Carousel>
        </section>
    )
}

export default Carousel_homepag4
