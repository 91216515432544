import React,{useState,useEffect} from 'react';
import './homepage.css';
import '../article/article.css';

import Header from '../../assets/homepage/home2_banner.svg';
import Page2 from './page2/page2';
import Page3 from './page3/page3';
import Page4 from './page4/page4';
import Page5 from './page5/page5';
import Bottom from '../../assets/common_footer/bottom.svg';

import SimpleSlider from './reactslickslider';

// api 
import { ApiHelper } from '../../utilFunctions/utilfunction';

const Homepage2 = () => {

    const[banner,setBanner] = useState();
    
    const[banner2,setBanner2] = useState();

    useEffect (async () =>{
        
        let url ="/page_banner_image?page_banner_key=home_banner_image"
        let bannerdata = await ApiHelper(url);
        // console.log(bannerdata.data["image_full_ path"]);
        setBanner(bannerdata.data["image_full_ path"]);

        let url2 ="/page_banner_image?page_banner_key=footer_image"
        let bannerdata2 = await ApiHelper(url2);
        // console.log(bannerdata2.data["image_full_ path"]);
        setBanner2(bannerdata2.data["image_full_ path"]);

    }, [])

    var divStyle = {
        backgroundImage: 'url(' + banner2 + ')'
    }

    return(
        <section>

            <div className="Articles p-navbar">

                <div className='container-fluid'>
                    <div className='container ps-lg-0 pe-lg-0'>
                        <SimpleSlider/>
                    </div>
                </div>
                {/*
                    <Page2/>

                */}
            
                <Page4/>
            
                <Page3/>
            
                {/* <Page5/> */}
                
                {/*
                <div className='position-relative bannerimage text-center mb-5' style={divStyle}>

                </div>
                
                
                <img className='w-100 mw-100' src={banner2} alt=""/>
                */}
            </div> 
        </section>
    )
};

export default Homepage2;
