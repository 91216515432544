import React,{useEffect, useState} from 'react';
import './article.css';
import { useParams } from 'react-router-dom';

import moment from 'moment';

import CommonFooter from '../common_footer/common_footer3';

import { useNavigate } from 'react-router';
import { useLocation } from "react-router-dom";
import { ApiHelper } from '../../utilFunctions/utilfunction';

const ArticleList = () => {

    // for seo friendly start
    let { id } = useParams();
    // console.log(id)
    let newid = id.replace('p', '');
    const [articlelistid,setArticlelistid] = useState(id);
    const [articlefriendly,setArticlefriendly] = useState();
    // for seo friendly End

    const[banner,setBanner] = useState();

    const navigate = useNavigate();

    let location= useLocation(); 
    // console.log(location.state,"hello");

    useEffect (async () =>{

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        
        let url ="/page_banner_image?page_banner_key=article_detail_banner_image"
        let bannerdata = await ApiHelper(url);
        setBanner(bannerdata.data["image_full_ path"]);

        let url2 = `/get_article/${newid}`;
        let articledata = await ApiHelper(url2);
        // console.log(articledata.data,"xyz");
        setArticlefriendly(articledata.data);

    }, [newid])

    // setTimeout(() =>{
    //     document.getElementById("dangerous").innerHTML = articlefriendly.description;
    // },1000)       

    var divStyle = {
        backgroundImage: 'url(' + banner + ')'
    }
    
    return (
        <section className='Articles articleList p-navbar'>

            <div className='position-relative bannerimage text-center mb-5' style={divStyle}>
                <div className="overlay-landingpage"></div>
            </div>
            {
                articlefriendly?(
                    <div className='container ps-lg-0 pe-lg-0'>
                
                        <h2 className='col-12 text-wrapp fw-700 text-dark f-42 text-uppercase'>{articlefriendly.title}</h2>

                        <p className='fs-18 c-4e4e4e'>{moment(articlefriendly.article_date).format('DD MMMM YYYY')}</p>

                        <div className='d-flex justify-content-center pt-2 pt-lg-4 pb-4'>
                            <div className='main_article_list_images'>
                                <img className='w-100 mw-100' src={articlefriendly.image_path} alt="image1"/>
                            </div>
                        </div>

                        <div className='article_imagess fs-16 fw-400 c-4e4e4e'  dangerouslySetInnerHTML={{__html: `${articlefriendly.description}`}}></div>

                        {/* <div className='d-flex justify-content-center'>
                            <p className='onhover cursor-pointer c-4e4e4e fs-16 fw-400 pt-4 pb-4' onClick={()=>navigate("/archive-articles")}>For more articles</p>
                        </div> */}
                    </div>
                )
                :""
            }

        {/* <CommonFooter/> */}
        </section>
    );
};

export default ArticleList;
