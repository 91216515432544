import React,{useState,useEffect}  from 'react';
import './collection.css'

import Header from '../../assets/collection/Header3.svg';

import UpperWatch1 from '../../assets/homepage/page2_upper1.svg';
import UpperWatch2 from '../../assets/homepage/page2_upper3.png';
import UpperWatch3 from '../../assets/homepage/page2_upper2.svg';
import UpperWatch4 from '../../assets/homepage/page2_upper4.svg';

import CommonFooter from '../common_footer/common_footer'

// api 
import { ApiHelper } from '../../utilFunctions/utilfunction';
import { useNavigate } from 'react-router';

const Archive = () => {
    
    const[archive,setArchive] = useState();

    const[banner,setBanner] = useState();

    useEffect (async () =>{
        
        let url = "/sold_watch_list"
        let archivedata = await ApiHelper(url);
        console.log(archivedata);
        setArchive(archivedata);

        let url2 ="/page_banner_image?page_banner_key=archives_banner_image"
        let bannerdata = await ApiHelper(url2);
        // console.log(bannerdata);
        setBanner(bannerdata.data["image_full_ path"]);

    }, [])

    const navigate = useNavigate();

    var divStyle = {
        backgroundImage: 'url(' + banner + ')'
    }

    return (
        
        <section className='archive collection p-navbar'>
            
            <div className='d-flex justify-content-center align-items-center position-relative bannerimage w-100 h-auto text-center mb-5' style={divStyle}>
                <h2 className='fs-48 w-100 pt-5 pb-5 text-light fw-700'>Our Recent Solds</h2>
            </div>

            {
                archive && archive.length?
                    <div className='text-center pb-4'>
                        <img className='mw-100' src={Header} alt='header'/>
                    </div>
                :null
            }

            <div className='container ps-lg-0 pe-lg-0'>
            {
                archive?(
            
                    archive.data.length?(
                        <section>
            
                            <div className=' d-flex flex-column flex-sm-row flex-wrap pt-4 pb-4'>

                            {
                                archive.data.map((value,index)=>

                                    value.watch_type == "vintage" ?
                                    (
                                        <div className='col-12 col-sm-6 col-lg-3 mb-4' key={index}>
                                            <div className='card cursor-pointer shadow_hover' style={{border:'none'}}>
                                                <div className='card-body text-center' onClick={()=>navigate("/archivedetail",{state : value})}>
                                                    <div className='watches'>
                                                    
                                                        <img className='mw-100' src={
                                                               value.watch_type == "vintage"? value.vintage_detail.watch_images[0]["image_full_path"]: null
                                                        } alt="watch"/>
                                                    </div>
                                                    <p className='p-text-height px-2 fs-16 fw-400 height-text-wrapp'>
                                                        { value.watch_type == "rolex" ? value.watch_detail &&  value.watch_detail.title :  value.vintage_detail && value.vintage_detail.title }
                                                    </p>
                                                    <button className='btn hover2 fs-14 fw-500 button_pd24'>More info</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :null
                                )
                            }
                            </div>
                        </section>
                        ):null
                    ):null
            }
            
            </div>

            <CommonFooter/>

        </section>
    )
};

export default Archive;
