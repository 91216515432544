import React,{useEffect, useState} from 'react';
import './article.css';
import Noblog from '../../assets/homepage/noblog.svg';

import Heading from '../../assets/homepage/page4_heading.svg';
import Header from '../../assets/homepage/page4_header.svg';
import Gun from '../../assets/homepage/page4_gun.svg';
import Clock from '../../assets/homepage/page4_clock.svg';

import Heading2 from '../../assets/Article/2_article_heading.svg';
import Content1 from '../../assets/Article/2_article_content1.svg';
import Content2 from '../../assets/Article/2_article_content2.svg';

import Bottom from '../../assets/common_footer/bottom.svg';

import Extra from '../../assets/Article/extra.png';

// api 
import { ApiHelper } from '../../utilFunctions/utilfunction';
import { useNavigate } from 'react-router';

const Article = () => {
    
    const[article,setArticle] = useState();

    const[banner,setBanner] = useState();

    const[banner3,setBanner3] = useState();

    const[seoarticle,SetSeoarticle] = useState();

    useEffect (async () =>{

        let url2 ="/page_banner_image?page_banner_key=article_banner_image"
        let bannerdata = await ApiHelper(url2);
        // console.log(bannerdata,"abcd");
        setBanner(bannerdata.data["image_full_ path"]);

        let url3 ="/page_banner_image?page_banner_key=footer_image"
        let bannerdata3 = await ApiHelper(url3);
        // console.log(bannerdata3.data["image_full_ path"]);
        setBanner3(bannerdata3.data["image_full_ path"]);

        let url ="/get_articles"
        let articledata = await ApiHelper(url);
        SetSeoarticle(articledata)
        let newArray = [];

        let abcd = articledata.data.length ? 
            articledata.data.map((value,index) => {
                let objToPush = {
                    id: value.id,
                    slug : value.slug,
                    article_date : value.article_date,
                    meta_description : value.meta_description,
                    description : value.description,
                    title : value.title,
                    image_path : value.image_path,
                    first: index%3 === 0 || index === 0 ? true : false,
                };
                newArray.push(objToPush);
            }):[]   
        setArticle(newArray)
    }, [])

    var divStyle = {
        backgroundImage: 'url(' + banner + ')'
    }
    var divStyle2 = {
        backgroundImage: 'url(' + banner3 + ')'
    }
    const navigate = useNavigate();

    
    return (
    <section className="Articles p-navbar">        

        <div className='d-flex justify-content-center align-items-center position-relative bannerimage text-center mb-5' style={divStyle}>
            <h2 className='page3_z-index2 fs-48 pt-5 pb-5 text-light fw-bold'>Articles </h2>
            <div className="overlay-landingpage"></div>
        </div>

        <div className='container p-0'>
       
        <div className='text-center '>
            <img className='mw-100' src={Heading} alt='header'/>
        </div>
            
        {
            article?(
                <div className='d-flex flex-column flex-sm-row flex-wrap pt-4 pb-0 pb-lg-4'>
                {
                    article.map((value,index)=>(
                        
                        <div className='col-12 col-sm-6 col-lg-4 col-xl-3 mb-3' key={index}>
                            <div className='card cursor-pointer shadow_hover' style={{border:'none'}} onClick={()=>navigate(`/articles/p${value.id}/${value.slug}`,{state : value} )}>
                                <div className='card-body position-relative'>
                                    <div className='watches flex-column mb-0 wathesss position-relative'>
                                        <img className='w-100 mw-100' src={value.image_path} alt="upperwatch1"/>
                                        <div className={`image_content col-12 bottom-0 start-0 p-0`}>
                                            <p className='fs-16 fw-400 text-center color-18191F for_height_list mt-3 mb-2'>{value.title}</p>
                                            <div className='d-flex justify-content-center mt-2'>
                                                <button className="btn hover2 fs-14 fw-500 button_pd24">Read More</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
            )
            : ""
        }
        {
            seoarticle?
                seoarticle.status == false &&
                <div className='pb-5 mt-0 d-flex flex-column align-items-center justify-content-center'>
                    <img style={{width:'200px'}} src={Noblog} alt="noblog"/>
                    <h4 className='pt-3' style={{color:'#484848'}}>No Articles Found</h4>
                </div>
            :""
        }
        </div>
        {/* second start */}
        {/*{
            article?(
                
                    <div className='d-flex flex-column flex-sm-row flex-wrap pt-4 pb-4'>
                    {
                        article.map((value,index)=>
                            (
                                <div className={`position-relative col-12 col-sm-6 col-lg-3 mb-4 article_image mb-5 p-0`} style={{backgroundImage: `url(${value.image_path})`}} key={index}>

                                    <img className='mw-100 w-100 article_below_576px' src={value.image_path} alt="header"/>
                                    <div className={`image_content col-12 col-md-10  bottom-0 text-light p-3`}>
                                        <p className='fs-18 fw-bold text-uppercase text-wrapp1'>{value.title}</p>
                                        <div className='d-flex justify-content-between'>
                                            <p className='fs-16 m-0 p_text'>{value.article_date}</p>
                                            <a className='fs-16 fw-bold text-light pe-4 p_text cursor-pointer' onClick={()=>navigate("/articlelist",{state : value} )}> Read more</a>
                                        </div>
                                    </div>
            
                                </div>
                            )
                        )
                    }
                    </div>
            )
            :null
            }
           */}
        {/* second end */}
        {/*
            {
                article?(
                    
                        <div className=''>
                        {
                            article.map((value,index)=>
                                (
                                    <div className={`position-relative ${value.first ? 'col-12' : 'col-6'} article_image mb-5 p-0`} style={{backgroundImage: `url(${value.image_path})`}} key={index}>

                                        <img className='mw-100 w-100 article_below_576px' src={value.image_path} alt="header"/>
                                        <div className={`image_content col-12 col-md-10 ${value.first ? 'col-lg-5' : 'col-lg-10'}  bottom-0 text-light p-3`}>
                                            <p className='fs-18 fw-bold text-uppercase text-wrapp1'>{value.title}</p>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fs-16 m-0 p_text'>{value.article_date}</p>
                                                <a className='fs-16 fw-bold text-light pe-4 p_text cursor-pointer' onClick={()=>navigate("/articlelist",{state : value} )}> Read more</a>
                                            </div>
                                        </div>
                
                                    </div>
                                )
                            )
                        }
                        </div>
                )
                :null
                }
        </div>
        */}
        {/*
            <div className='position-relative bannerimage text-center mb-5' style={divStyle2}>

            </div>
       
        <img className='w-100 mw-100' src={banner3} alt=""/>
        */}
    </section>
    )
}

export default Article;
