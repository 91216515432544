import React,{useState,useEffect,useRef} from 'react';
import './contact.css';
import CommonFooter2 from '../common_footer/common_footer';

import Contact1 from '../../assets/contactus/contact1.svg';
import Contact2 from '../../assets/contactus/contact2.svg';
import Contact3 from '../../assets/contactus/contact3.svg';
import Contact4 from '../../assets/contactus/contact4.svg';
import Contact5 from '../../assets/contactus/contact5.svg';
import Contact6 from '../../assets/contactus/contact6.svg';
import Contact7 from '../../assets/contactus/contact7.svg';
import Contact8 from '../../assets/contactus/contact8.svg';

import ReCAPTCHA from 'react-google-recaptcha';

import SubmitIcon from  '../../assets/contactus/submit.svg';

// validation
import { useForm } from 'react-hook-form';

// API
import { ApiHelper } from '../../utilFunctions/utilfunction';
// import recaptcha from 'react-google-recaptcha/lib/recaptcha';

const Contact = () => {

    const dangerous = useRef(null);

    // For message which display when submit the form
    const [ flashmessage , setFlashmessage ] = useState(false)

    // For recaptcha 
    const [ recaptcha,setRecaptcha ] = useState(true);

    // For validation
    
    const[messagess,setMessagess] = useState('');

    // console.log(messagess);
    const {register,handleSubmit,formState:{errors},reset,trigger} = useForm();

    const Submit = (event) =>{
        
        reset();

        // For API
        const name = event.name
        const email = event.email
        const phone = event.phone
        const location = event.location
        const subject = event.subject
        const enquiry = event.enquiry
        const message = messagess

        const reqToSend = {

            name:name,
            email: email,
            phone_no: phone,
            location: location,
            subject: subject,
            enquiry: enquiry,
            // message: message.length > 0 ? message : "",
            message: message
        }

        let url = "/contact_us";
        ApiHelper(url, reqToSend, "POST")
        .then(response => console.log(response))

        window.grecaptcha.reset();

        setFlashmessage(true);

        setMessagess('');
        
        setRecaptcha(true);

        setTimeout(() => {
            
            setFlashmessage(false)

        }, 3000);
    }
    
    const[contactdesc, setContactdesc] = useState();

    useEffect(async () => {
      
        let url2 = "/get_contact_us/content";
        let selldata = await ApiHelper(url2);
        // console.log(selldata);
        setContactdesc(selldata);

        var a = document.getElementById("dangerous")
        a.innerHTML = selldata.data.description;
            
    }, []);

    const handleChange = (e) => {
        console.log(e, "123321")
        e ? setRecaptcha(false) : setRecaptcha(true);
    }

    return (
        <section className='contactus p-navbar'>

            <div className='common_form container pt-5 ps-lg-0 pe-lg-0'>

                <h4 className='fw-bold text-uppercase fs-18'>Contact us</h4>
                
                {/*** Form ***/}
                <form className="form pt-4" onSubmit={handleSubmit(Submit)}>
                    
                    <div className="row d-flex flex-wrap justify-content-between">
                        
                        <div className='col-12 col-lg-6 pe-lg-4'>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("name", { required: "Name is required"})}  onKeyUp={()=>{ trigger("name") }}/>
                                {errors.name && (<p className='position-absolute text-danger'>{errors.name.message}</p>)}
                                <label htmlFor="inputText">Your Name*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text" 
                                {...register("email", { required: "Email is required", 
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid Email Address"
                                        }
                                    })
                                } 
                                onKeyUp={()=>{
                                    trigger("email")
                                }}/>
                                {errors.email && (<p className='position-absolute text-danger'>{errors.email.message}</p>)}
                                <label htmlFor="inputText">Email Address*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="number"
                                {...register("phone", { required: "Phone Number is required", 
                                        pattern: {
                                            value: /^[0-9]{8,15}$/,
                                            message: "Invalid Phone Number"
                                        }
                                    })
                                }
                                onKeyUp={()=>{ trigger("phone")}}/>
                                {errors.phone && (<p className='position-absolute text-danger'>{errors.phone.message}</p>)}
                                <label htmlFor="inputText">Phone Number*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("location", { required: "Location is required"})} onKeyUp={()=>{ trigger("location") }}/>
                                {errors.location && (<p className='position-absolute text-danger'>{errors.location.message}</p>)}
                                <label htmlFor="inputText">Location*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("subject", { required: "Subject is required"})} onKeyUp={()=>{ trigger("subject") }}/>
                                {errors.subject && (<p className='position-absolute text-danger'>{errors.subject.message}</p>)}
                                <label htmlFor="inputText">Subject*</label>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6 ps-lg-4'>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("enquiry", { required: "Enquiry is required"})} onKeyUp={()=>{ trigger("enquiry") }}/>
                                {errors.enquiry && (<p className='position-absolute text-danger'>{errors.enquiry.message}</p>)}
                                <label htmlFor="inputText">Enquiry*</label>
                            </div>
                            <div className="textOnInput textOnInput2 mb-4">
                                <textarea className="form-control" rows="3" onChange={(e)=>setMessagess(e.target.value)}/>
                                <label htmlFor="exampleFormControlTextarea1" className="form-label m-0">Additional Message</label>
                            </div>

                            {/*** Recaptcha***/}
                            <ReCAPTCHA id="recaptcha" sitekey='6LcGAtEgAAAAAHyP6eLkslZXPBdAVZA0NHYMgBlo' onChange={handleChange}/>
                            
                            <div className='d-flex flex-wrap mt-4 mt-md-5 mt-lg-4 align-items-center'>
                                <button type="submit" className='fw-600 btn hover2 submit_button' disabled={recaptcha}>Submit</button>
                                {flashmessage?
                                    <div className='d-flex align-items-center pt-2 pt-sm-0'><img className='ps-sm-4 pe-2' src={SubmitIcon} alt=""/><span className='fs-5'>Form Submitted Successfully</span></div>
                                :null}
                            </div>
                        </div>
                    </div>
                    
                </form>

                                
                                    


                {
                    contactdesc?
                        <section>
                            <div id="dangerous" className='fs-52 fw-400' dangerouslySetInnerHTML={{__html: `${contactdesc.data.description}`}} ></div>
                        </section>
                    :null
                }


            </div>

           {/* <CommonFooter2/> */} 

        </section>
    )
}

export default Contact;
