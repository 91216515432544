import React,{useState,useEffect} from 'react';
import './collection.css'

import Header from '../../assets/collection/Header2.svg';

import UpperWatch1 from '../../assets/homepage/page2_upper1.svg';
import UpperWatch2 from '../../assets/homepage/page2_upper3.png';
import UpperWatch3 from '../../assets/homepage/page2_upper2.svg';
import UpperWatch4 from '../../assets/homepage/page2_upper4.svg';

import CommonFooter from '../common_footer/common_footer'

// api 
import { ApiHelper } from '../../utilFunctions/utilfunction';
import { useNavigate } from 'react-router';

const Rolex = () => {
    
    const[rolex,setRolex] = useState();

    const[banner2,setBanner2] = useState();

    useEffect (async () =>{
        
        let url ="/get_watch_list"
        let rolexdata = await ApiHelper(url);
        // console.log(rolexdata);
        setRolex(rolexdata);
        
        let url2 ="/page_banner_image?page_banner_key=rolex_banner_image"
        let bannerdata = await ApiHelper(url2);
        // console.log(bannerdata);
        setBanner2(bannerdata.data["image_full_ path"]);
    }, [])

    const navigate = useNavigate();

    var divStyle = {
        backgroundImage: 'url(' + banner2 + ')'
    }

    return (
        
        <section className='collection rolex p-navbar'>
            

            <div className='d-flex justify-content-center align-items-center position-relative bannerimage w-100 h-auto text-center mb-4' style={divStyle}>
                <h2 className='fs-48 w-100 text-light pt-5 pb-5 fw-700'>Experience our Rolex Collection</h2>
            </div>

            {
                rolex && rolex.data.length?
                    <div className='text-center pb-4'>
                        <img className='mw-100' src={Header} alt='header'/>
                    </div>
                :null
            }
            <div className='container ps-lg-0 pe-lg-0'>

            {
                rolex?(
                    
                        <section>

                            <div className=' d-flex flex-column flex-sm-row flex-wrap pt-4 pb-4'>

                            {
                                rolex.data.map((value,index)=>
                                    (
                                        <div className='col-12 col-sm-6 col-lg-3 mb-4' key={index}>
                                            <div className='card cursor-pointer shadow_hover' style={{border:'none'}}>
                                                <div className='card-body text-center'  onClick={()=>navigate("/rolexdetail",{state : value})}>
                                                    <div className='watches'>
                                                        <img className='kuchbhi mw-100' src={value.watch_images.length > 0 ? value.watch_images[0]["image_full_path"] : UpperWatch2} alt="upperwatch1"/>
                                                    </div>
                                                    <p className='p-text-height px-2 fs-16 fw-400 height-text-wrapp'>{value.title}</p>
                                                    <button className='btn hover2 fs-14 fw-500 button_pd24'>More info</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                            </div>
                        </section>
                    
                    ):null
            }

                
            </div>

            <CommonFooter/>

        </section>
    )
};

export default Rolex;
