import React,{useRef,useState} from 'react'
import './collection.css';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';

import ReCAPTCHA from 'react-google-recaptcha';

import CommonFooter from '../common_footer/common_footer';

import UpperWatch2 from '../../assets/homepage/page2_upper3.png';

import SubmitIcon from  '../../assets/contactus/submit.svg';

// validation
import { useForm } from 'react-hook-form';

import { useLocation } from "react-router-dom";

// API
import { ApiHelper } from '../../utilFunctions/utilfunction';

const Rolex_purchase = () => {
    
    let location= useLocation(); 
    // console.log(location.state);

    // For message which display when submit the form
    const [ flashmessage , setFlashmessage ] = useState(false)

    const dangerous = useRef(null);

    const[watchdetailpurchase,setWatchdetailpurchase] = useState(location.state);

    // For recaptcha 
    const [ recaptcha,setRecaptcha ] = useState(true);

    const {register,handleSubmit,formState:{errors},reset,trigger} = useForm();

    let watchid = location.state.id;
    let watchname = location.state.title;
    let watchref = location.state.ref;

    const[messagess,setMessagess] = useState('');


    const Submit = (event) =>{
        reset();

        // For API
        const name = event.name
        const email = event.email
        const phone = event.phone
        const message = messagess
        
        const reqToSend = {

            name:name,
            email: email,
            phone_no: phone,
            message: message,
            watch_type : "rolex",
            watch_id : watchid
        }

        let url = "/order";
        ApiHelper(url, reqToSend, "POST")
        .then(response => console.log(response) )

        window.grecaptcha.reset();

        setFlashmessage(true);

        setRecaptcha(true);
       
        setMessagess('');
        
        setTimeout(() => {
            
            setFlashmessage(false)

        }, 3000);
    }

    const handleChange = (e) => {

        e ? setRecaptcha(false) : setRecaptcha(true);
    }

    const[sliderimage,setSliderimage] = useState();

    const[display,setDisplay] = useState(false);

    const handleclick = (value) =>{
        console.log(value.image_full_path,"abcd hai");
        setSliderimage(value.image_full_path);
        setDisplay(true);
    }

    return (

    <section>

        {
            display ?
                <div className='position-fixed parent_class'>
                    <div className='position-fixed d-flex justify-content-center align-items-center bg-gradient'>
                        <img className='slider_image_width' src={sliderimage} alt="slider"/>
                    </div>
                    <svg onClick={() => {setDisplay(false)}} className='position-fixed slider_close_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"/></svg>
                </div>
            : ""
        }

    <div className='container ps-lg-0 pe-lg-0 p-navbar'>
    <form className="form pt-4" onSubmit={handleSubmit(Submit)}>
        <h4 className='fw-bold text-uppercase pt-5 fs-18'>Customer order information</h4>

        <div className='d-flex flex-column-reverse flex-lg-row'>
            <div className='col-12 col-lg-5'>

                {/*** Form ***/}
                <div className='pt-4'>

                    <div className="textOnInput">
                        <input className="form-control" type="text" {...register("name", { required: "Name is required"})}  onKeyUp={()=>{ trigger("name") }}/>
                        {errors.name && (<p className='position-absolute text-danger'>{errors.name.message}</p>)}
                        <label htmlFor="inputText">Customer Name*</label>
                    </div>
                    <div className="textOnInput">
                        <input className="form-control" type="text"
                        {...register("email", { required: "Email is required", 
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid Email Address"
                                }
                            })
                        } 
                        onKeyUp={()=>{
                            trigger("email")
                        }}/>
                        {errors.email && (<p className='position-absolute text-danger'>{errors.email.message}</p>)}
                        <label htmlFor="inputText">Email Address*</label>
                    </div>
                    <div className="textOnInput">
                        <input className="form-control" type="number" 
                        {...register("phone", { required: "Phone Number is required", 
                                pattern: {
                                    value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                    message: "Invalid Phone Number"
                                }
                            })
                        }
                        onKeyUp={()=>{ trigger("phone")}} />
                        {errors.phone && (<p className='position-absolute text-danger'>{errors.phone.message}</p>)}
                        <label htmlFor="inputText">Phone Number*</label>
                    </div>
                    <div className="textOnInput">
                        <input className="form-control" type="text" defaultValue={watchname} disabled/>
                        <label htmlFor="inputText">Make of Watch*</label>
                    </div>
                    <div className="textOnInput">
                        <input className="form-control" type="text"  defaultValue={watchref} disabled/>
                        <label htmlFor="inputText">Ref*</label>
                    </div>
                    
                    <div className="textOnInput textOnInput2 mb-4">
                        <textarea className="form-control" rows="3" onChange={(e)=>setMessagess(e.target.value)}/>
                        <label htmlFor="exampleFormControlTextarea1" className="form-label m-0">Additional Message</label>
                    </div>

                    {/*** Recaptcha***/}
                    <ReCAPTCHA id="recaptcha" sitekey='6LcGAtEgAAAAAHyP6eLkslZXPBdAVZA0NHYMgBlo' onChange={handleChange}/>

                </div>

            </div>
            <div className='col-12 col-lg-7'>

                <section className="homepage4_thumb_carousel">
                    <Carousel autoplay showIndicators={false} showArrows={false} showStatus={false}>
                    {
                        
                        watchdetailpurchase.watch_images.length > 0 ? 
                                
                            watchdetailpurchase.watch_images.map((value,index)=>(

                                    <div className="imagge m-1 m-md-2" key={index}  onClick={()=>handleclick(value)}>
                                        <img src={value["image_full_path"]} alt=""/>
                                    </div>
                                ))
                        : 
                    
                        <div className="imagge m-1 m-md-2">
                            <img src={UpperWatch2} alt=""/>
                        </div>
                    }
                    </Carousel>
                </section>

            </div>
                
        </div>

        <div className='d-flex flex-wrap flex-md-nowrap mt-4 mt-md-5 mt-lg-4 align-items-center'>
        <button type="submit" className='btn hover2  fw-500 submit_button' disabled={recaptcha}>Submit</button>
        {flashmessage?
            <div className='d-flex align-items-center pt-4 pt-md-0'><img className='ps-md-4 pe-2' src={SubmitIcon} alt=""/><span className='fs-5'>Thanks for showing interest in making a purchase. We have received your request and will contact you shortly.</span></div>
        :null}
    </div>
    </form>
    </div>

    <CommonFooter/>

    </section>
  )
}

export default Rolex_purchase