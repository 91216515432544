import React,{useEffect, useRef, useState} from 'react';
import './collection.css';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';

import UpperWatch2 from '../../assets/homepage/page2_upper3.png';

import CommonFooter2 from '../common_footer/common_footer2';

import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router';

const Rolex_pd = () => {
    
    const dangerous = useRef(null);

    let location= useLocation(); 
    // console.log(location.state.id);

    const[watchdetail,setWatchdetail] = useState(location.state);
    // console.log(watchdetail,"hjsagdgsajhdghsagdjsaghjdsg");

    setTimeout(() =>{
            
        document.getElementById("dangerous").innerHTML = location.state.description;

    },1000) 

    useEffect(()=>{
        setWatchdetail(location.state);
    },[location.state])

    const navigate = useNavigate();

    const[sliderimage,setSliderimage] = useState();

    const[display,setDisplay] = useState(false);

    const handleclick = (value) =>{
        console.log(value.image_full_path,"abcd hai");
        setSliderimage(value.image_full_path);
        setDisplay(true);
    }
    return (
    <section>

        {
            display ?
                <div className='position-fixed parent_class'>
                    <div className='position-fixed d-flex justify-content-center align-items-center bg-gradient'>
                        <img className='slider_image_width' src={sliderimage} alt="slider"/>
                    </div>
                    <svg onClick={() => {setDisplay(false)}} className='position-fixed slider_close_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"/></svg>
                </div>
            : ""
        }

        <div className='container ps-lg-0 pe-lg-0 p-navbar'>
            <div className='d-flex flex-wrap'>            
                <div className='col-12 col-lg-6'>
                    <p className='pt-5 color-secondary text-uppercase fs-14'>Experience our Rolex Collection</p>
                    <h2 className='fs-40 fw-500 m-0 pb-2 text-capitalize'>{location.state.title}</h2>
                    <h5 className='fs-18 fw-500 text-uppercase'>serial no: {location.state.serial_no}</h5>
                    <h5 className='fs-18 fw-500 text-uppercase'>Ref: {location.state.ref}</h5>

                    <div ref={dangerous} id="dangerous" className='fs-16 fw-400 pb-3'></div>
                    
                    <button className='btn fs-16 fw-500 hover2 rounded-3 ps-3 pt-2 pb-2 pe-3' onClick={()=>navigate("/rolexpurchase",{state : watchdetail})}>Contact Us</button>
                </div>
                <div className='col-12 col-lg-6 pt-5'>

                {/****** Carousel **********/}
                
                <section className="homepage4_thumb_carousel">
                    <Carousel autoplay showIndicators={false} showArrows={false} showStatus={false}>
                    {
                        
                        watchdetail.watch_images.length > 0 ? 
                                
                            watchdetail.watch_images.map((value,index)=>(
                                
                                <div className="imagge m-1 m-md-2" key={index} onClick={()=>handleclick(value)}>
                                    <img src={value["image_full_path"]} alt=""/>
                                </div>
                            ))
                        : 
                    
                        <div className="imagge m-1 m-md-2">
                            <img src={UpperWatch2} alt=""/>
                        </div>
                    }
                                     
                    </Carousel>
                </section>

            </div>
            </div>
        </div>
        
        <CommonFooter2/>

    </section>
    );
};

export default Rolex_pd;
