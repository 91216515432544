import React,{useState,useEffect} from 'react';
import './common_footer.css';
import '../article/article.css';

import Header from '../../assets/common_footer/CF_Articles.svg';
import Content from '../../assets/common_footer/CF_Content.svg';
import Bottom from '../../assets/common_footer/bottom.svg';

// api 
import { ApiHelper } from '../../utilFunctions/utilfunction';
import { useNavigate } from 'react-router';
import { useLocation } from "react-router-dom";

const Common_footer = () => {
     
    const[common,setCommon] = useState();

    const[banner3,setBanner3] = useState();

    let location= useLocation(); 

    useEffect (async () =>{
        
        let url ="/get_articles"
        let commondata = await ApiHelper(url);
        setCommon(commondata);

        let url2 ="/page_banner_image?page_banner_key=footer_image"
        let bannerdata = await ApiHelper(url2);
        setBanner3(bannerdata.data["image_full_ path"]);

    }, [location.state])

    var divStyle = {
        backgroundImage: 'url(' + banner3 + ')'
    }

    const navigate = useNavigate();

    // console.log(common)

    return (
        <section className='Articles commonfooter pt-3'>
            
            <div className='container ps-lg-0 pe-lg-0'>

                {
                    common ?(
                        common.data.length ?
                            <div className='text-center pb-5'>
                                <img className='mw-100' src={Header} alt='header'/>
                            </div>
                        :null
                    ):null
                }
                
                
                {
                    common ?(
                        
                        common.data.length ?

                        <section>

                            <div className={`position-relative col-12  article_image mb-5 cursor-pointer p-0`} style={{backgroundImage: `url(${common.data.length ? common.data[0].image_path : null })`}}  onClick={()=>navigate(`/articles/p${common.data[0].id}/${common.data[0].title.replaceAll("/", "-").replaceAll(" ","-").toLowerCase()}`,{state : common.data.length ? common.data[0] : null})}>

                                <img className='mw-100 w-100 article_below_576px' src={common.data[0].image_path} alt="header"/>
                                <div className={`image_content col-12 col-md-10 col-lg-5 bottom-0 text-light p-3`}>
                                    <p className='fs-18 fw-bold text-uppercase text-wrapp1'>{common.data.length ? common.data[0].title : null}</p>
                                    <div className='d-flex justify-content-between'>
                                        <p className='fs-16 m-0 p_text'>{common.data.length ? common.data[0].article_date : null}</p>
                                        <a className='fs-16 fw-bold text-light pe-4 p_text'> Read more</a>
                                    </div>
                                </div>
    
                            </div>

                        </section>

                        :null
                    
                    ):null
                }

            </div>

            {
                common ?(
                    common.data.length ?
                        <div className='text-center pb-5'>
                            <button className='fs-16 btn explore_button fw-500 ps-4 pe-4 pt-2 pb-2' onClick={()=>navigate('/archive-articles')} >Explore Articles</button>
                        </div>
                    :null
                ):null
            }
            
            {/*
            <div className='position-relative bannerimage text-center mb-3' style={divStyle}>
                
            </div>
         
            <img className='w-100 mw-100' src={banner3} alt=""/>

            */}
        </section>
    )
}

export default Common_footer;
