import React, { useState, useEffect } from "react";

import "./common_footer.css";
import CommonFooter from "./common_footer";
import { useParams } from "react-router";

import Header from "../../assets/common_footer/CF2_Header.svg";
import UpperWatch1 from "../../assets/homepage/page2_upper1.svg";
import UpperWatch2 from "../../assets/homepage/page2_upper3.png";
import UpperWatch3 from "../../assets/homepage/page2_upper2.svg";
import UpperWatch4 from "../../assets/homepage/page2_upper4.svg";

// api
import { useLocation } from "react-router-dom";
import { ApiHelper } from "../../utilFunctions/utilfunction";
import { useNavigate } from "react-router";

const Common_footer2 = () => {
  let { id } = useParams();
  let newid = id.replace("p", "");
  // console.log(newid);

  const [rolex, setRolex] = useState();

  let location = useLocation();
  

  useEffect(async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let url = `/get_vintage_watch_list?curent_watch_id=${newid}&recommend=true`;
    let rolexdata = await ApiHelper(url);
    // console.log(rolexdata);
    // console.log(location.state);

    // let abcd = rolexdata.data.filter( value => {
    //     return value.id !== location.state.id
    // })
    // console.log(abcd);
    setRolex(rolexdata.data);
  }, [id]);

  const navigate = useNavigate();

  return (
    <section>
      <div className="container ps-lg-0 pe-lg-0">
        {rolex && rolex.length ? (
          <div className="text-center pt-4 pb-4 pt-sm-5 pb-sm-5">
            <img className="mw-100" src={Header} alt="header" />
          </div>
        ) : null}

        {rolex ? (
          <section>
            <div className=" d-flex flex-column flex-sm-row flex-wrap pt-4 pb-4">
              {rolex.map((value, index) => (
                <div className="col-12 col-sm-6 col-lg-3 mb-4" key={index}>
                  <div
                    className="card cursor-pointer shadow_hover"
                    style={{ border: "none" }}
                  >
                    <div
                      className="card-body text-center"
                      onClick={() =>
                        navigate(
                          `/watches/p${value.id}/${value.title
                            .replaceAll("/", "-")
                            .replaceAll(" ", "-")
                            .toLowerCase()}`,
                          { state: value }
                        )
                      }
                    >
                      <div className="watches">
                        <img
                          className="mw-100"
                          src={
                            value.image_full_path
                              ? value.image_full_path
                              : UpperWatch2
                          }
                          alt="upperwatch1"
                        />
                      </div>
                      <p className="p-text-height px-2 fs-16 fw-400 height-text-wrapp">
                        {value.title}
                      </p>
                      <button className="btn hover2 fs-14 fw-500 button_pd24">
                        {" "}
                        More info
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        ) : null}
      </div>

      {/* <CommonFooter/> */}
    </section>
  );
};

export default Common_footer2;
