import React,{useState,useEffect,useRef} from 'react';

import Heading from '../../assets/sell_watch/sw_heading.svg';

import CommonFooter from '../common_footer/common_footer';

import ReCAPTCHA from 'react-google-recaptcha';

import UpperWatch2 from '../../assets/homepage/page2_upper3.png';

import SubmitIcon from  '../../assets/contactus/submit.svg';

import sw1 from '../../assets/sell_watch/sw1.svg';
import sw2 from '../../assets/sell_watch/sw2.svg';
import sw3 from '../../assets/sell_watch/sw3.svg';
import sw4 from '../../assets/sell_watch/sw4.svg';
import sw5 from '../../assets/sell_watch/sw5.svg';
import sw6 from '../../assets/sell_watch/sw6.svg';
import sw7 from '../../assets/sell_watch/sw7.svg';
import sw8 from '../../assets/sell_watch/sw8.svg';

// validation
import { useForm } from 'react-hook-form';

// API
import { ApiHelper } from '../../utilFunctions/utilfunction';
import { useNavigate } from 'react-router';

const Sellwatch = () => {

    const dangerous = useRef(null);

    // For message which display when submit the form
    const [ flashmessage , setFlashmessage ] = useState(false)

    const {register,handleSubmit,formState:{errors},reset,trigger} = useForm();
   
    const[messagess,setMessagess] = useState('');

    const Submit = (event) =>{
        
        reset();

        // for API
        const name = event.name
        const email = event.email
        const location = event.location
        const phone = event.phone
        const watch = event.watch
        const description = event.description
        const price = event.price
        const opmessage = messagess
        
        const reqToSend = {

            name:name,
            email: email,
            phone_no: phone,
            location: location,
            make_watch : watch,
            description : description,
            price : price,
            message : opmessage
        }

        let url = "/customer/sell/request";
        ApiHelper(url, reqToSend, "POST")
        .then(response => console.log(response))

        window.grecaptcha.reset();

        setFlashmessage(true);
        
        setMessagess('');

        setRecaptcha(true);

        setTimeout(() => {
            
            setFlashmessage(false)

        }, 3000);

    }

    const[sellwatchcontent,setSellwatchcontent] = useState();

    // For recaptcha 
    const [ recaptcha,setRecaptcha ] = useState(true);

    const[rolex,setRolex] = useState();

    useEffect(async () => {
      
        let url2 = "/sell_watch_content";
        let selldata = await ApiHelper(url2);
        // console.log(selldata);
        setSellwatchcontent(selldata);
        
        let url3 = "/get_vintage_watch_list";
        let rolexdata = await ApiHelper(url3);
        setRolex(rolexdata);

      if (selldata.data.length > 0){
        document.getElementById("dangerous").innerHTML = selldata.data[0].description;
      }  
    }, []);

    const navigate = useNavigate();

    const handleChange = (e) => {
        console.log(e, "123321")
        e ? setRecaptcha(false) : setRecaptcha(true);
    }

    return (
        <section className='SellWatch p-navbar'>

            <div className='common_form container pt-5 ps-lg-0 pe-lg-0'>
               
                <h4 className='fw-bold text-uppercase fs-18'>Sell Your Watch</h4>
                
                {/*** Form ***/}
                <form className="form pt-4" onSubmit={handleSubmit(Submit)}>

                    <div className="row d-flex flex-wrap justify-content-between">

                        <div className='col-12 col-lg-6 pe-lg-4'>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("name", { required: "Name is required"})}  onKeyUp={()=>{ trigger("name") }}/>
                                {errors.name && (<p className='position-absolute text-danger'>{errors.name.message}</p>)}
                                <label htmlFor="inputText">Name*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text" 
                                {...register("email", { required: "Email Address is required", 
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid Email Address"
                                        }
                                    })
                                } 
                                onKeyUp={()=>{
                                    trigger("email")
                                }}/>
                                {errors.email && (<p className='position-absolute text-danger'>{errors.email.message}</p>)}
                                <label htmlFor="inputText">Email Address*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("location", { required: "Location is required"})} onKeyUp={()=>{ trigger("location") }}/>
                                {errors.location && (<p className='position-absolute text-danger'>{errors.location.message}</p>)}
                                <label htmlFor="inputText">Location*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="number" 
                                {...register("phone", { required: "Phone Number is required", 
                                        pattern: {
                                            value: /^[0-9]{8,15}$/,
                                            message: "Invalid Phone Number"
                                        }
                                    })
                                }
                                onKeyUp={()=>{ trigger("phone")}}/>
                                {errors.phone && (<p className='position-absolute text-danger'>{errors.phone.message}</p>)}
                                <label htmlFor="inputText">Phone Number*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("watch", { required: "Make of Watch is required"})} onKeyUp={()=>{ trigger("watch") }}/>
                                {errors.watch && (<p className='position-absolute text-danger'>{errors.watch.message}</p>)}
                                <label htmlFor="inputText">Make of Watch*</label>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6 ps-lg-4'>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("description", { required: "Description is required"})} onKeyUp={()=>{ trigger("description") }}/>
                                {errors.description && (<p className='position-absolute text-danger'>{errors.description.message}</p>)}
                                <label htmlFor="inputText">Description of Watch*</label>
                            </div>
                            <div className="textOnInput">
                                <input className="form-control" type="text" {...register("price", { required: "Asking Price is required", maxLength:{
                                    value:8,
                                    message:"Maximum 8 digit prices are allowed"
                                }
                                })} onKeyUp={()=>{ trigger("price") }}/>
                                {errors.price && (<p className='position-absolute text-danger'>{errors.price.message}</p>)}
                                <label htmlFor="inputText">Asking price*</label>
                            </div>
                            <div className="textOnInput mb-4">
                                <input className="form-control" type="text" onChange={(e)=>setMessagess(e.target.value)}/>
                                <label htmlFor="inputText">Optional Message</label>
                            </div>
                           

                           {/*** Recaptcha***/}
                           <ReCAPTCHA id="recaptcha" sitekey='6LcGAtEgAAAAAHyP6eLkslZXPBdAVZA0NHYMgBlo' onChange={handleChange}/>

                            <div className='d-flex flex-wrap mt-4 mt-md-5 mt-lg-4 align-items-center'>
                                <button type="submit" className='btn hover2 fw-600 fs-16 rounded-3 submit_button'  disabled={recaptcha}>Submit</button>
                                {flashmessage?
                                    <div className='d-flex align-items-center pt-2 pt-sm-0'><img className='ps-4 pe-2' src={SubmitIcon} alt=""/><span className='fs-5'>Form Submitted Successfully</span></div>
                                :null}
                            </div>
                        </div>
                    </div>
                            
                </form>

                
                {
                    sellwatchcontent?(
                        
                            <section>
                            {
                                sellwatchcontent.data.map((value,index)=>
                                    (
                                        <div ref={dangerous} id="dangerous" className='article_imagess fs-16 fw-400' key={index}></div>
                                    )
                                )
                            }
                            </section>
                        
                        ):null
                }

                {/* {
                    rolex?(
                        
                            <section>
    
                                <div className=' d-flex flex-column flex-sm-row flex-wrap pt-4'>
    
                                {
                                    rolex.data.map((value,index)=>
                                        (
                                            <div className='col-12 col-sm-6 col-lg-3 mb-4' key={index}>
                                                <div className='card' style={{border:'none'}}>
                                                    <div className='card-body text-center'>
                                                        <div className='watches'>
                                                            <img className='mw-100' src={value.watch_images.length > 0 ? value.watch_images[0]["image_full_path"] : UpperWatch2} alt="upperwatch1"/>
                                                        </div>
                                                        <p className='p-text-height px-2 fs-16 fw-400'>{value.title}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                                </div>
                            </section>
                        
                        ):null
                } */}
    
                
            </div>
            {/* <CommonFooter/> */}

        </section>
    )
}

export default Sellwatch;
