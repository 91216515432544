import React, { Component,useState,useEffect } from "react";
import Slider from "react-slick";
import Header from '../../assets/homepage/home2_banner.svg';
import FooterImage from '../../assets/common_footer/bottom.svg';

// api 
import { ApiHelper } from '../../utilFunctions/utilfunction';

const SimpleSlider = () => {

    const[banner2,setBanner2] = useState();

    // const[banner,setBanner] = useState();

    // const[banner3,setBanner3] = useState();

    useEffect (async () =>{

        // let url2 ="/page_banner_image?page_banner_key=home_banner_image"
        // let bannerdata = await ApiHelper(url2);
        // // console.log(bannerdata,"abcd");
        // setBanner(bannerdata.data["image_full_ path"]);

        // let url3 ="/page_banner_image?page_banner_key=footer_image"
        // let bannerdata3 = await ApiHelper(url3);
        // // console.log(bannerdata3.data["image_full_ path"]);
        // setBanner3(bannerdata3.data["image_full_ path"]);

        let url = "/get_page_banner";
        let homepagebanner = await ApiHelper(url);
        console.log(homepagebanner,"homepage banner data")
        setBanner2(homepagebanner);

    }, [])

    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 7000,
      cssEase: "linear",
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>

        <Slider {...settings}  className="w-100 mw-100 overflow-hidden">
        
          {
            banner2?(
                banner2.data.map((value,index)=>(
                  <div key={index} >
                    <img className="w-100" src={value.image_path} alt=""/>
                  </div>
                ))
            ): <div></div>
          }

        </Slider>
      </div>
    );
  }

export default SimpleSlider